
import { Component, Prop, Vue } from "vue-property-decorator";
import { Payment, PaymentInterface } from "../../../store/modules";

@Component
export default class PaymentStatusBadge extends Vue {
  @Prop(Payment)
  private record!: PaymentInterface;

  text() {
    switch (true) {
      case Boolean(this.record.voided):
        return "Voided";
      case Boolean(this.record.approved):
        return "Approved";
    }

    return "Pending";
  }

  color() {
    switch (true) {
      case Boolean(this.record.voided):
        return "danger";
      case Boolean(this.record.approved):
        return "primary";
    }

    return "warning";
  }
}
