
import { PaymentFilterQueryPayload } from "@/store/modules";
import Multiselect from "vue-multiselect";
import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

type OptionProps = { text: string; value: string };

@Component({ components: { Multiselect } })
export default class PaymentStatusDropdown extends Vue {
  filter: PaymentFilterQueryPayload = {};

  // @Model("change", { type: Array, default: () => [] })

  // @PropSync("selected", { default: () => '', type: String })
  start: string = '';
  end: string = '';

  // @Watch("selected")
  // onSelectChange() {
  //   console.log('yyyyyyyyyyyyyyyyy', this.start)
  //   // console.log("B", this.selectedSync);
  //   // this.$emit("select", this.selectedSync);
  // }

  onInput() {
    this.$emit("select", {
      start: this.start,
      end: this.end
    });
  }


  /*get availableOptions() {
    return this.options;
  }


  onSelect(selectedOption: OptionProps, id: number) {
    this.mappedTags.push(selectedOption.value);
    this.$emit("select", this.mappedTags);
  }

  onRemove({ value }: OptionProps) {
    const i = this.mappedTags.findIndex((t) => t === value);
    this.mappedTags.splice(i, 1);
    this.$emit("select", this.mappedTags);
  }*/

}
