
import { Component, Watch, Vue } from "vue-property-decorator";
import { StripeList } from "./lists/StripeList.vue";
import {
  PaymentFilterQueryPayload,
  PaymentAllPayload,
  Payment,
} from "@/store/modules";
import { CDataTableSortVal } from "@/@types";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import { SortQueryPayload } from "@/store/modules";
import StripeStatusDropdown from "./forms/StripeStatusDropdown.vue";
import moment from 'moment';

@Component({
  components: { StripeList, StripeStatusDropdown },
})
export default class StripeListHandler extends Vue {

  limit = 99999;

  colFilter: PaymentFilterQueryPayload = {};

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {};

  filterStart!: string | null;
  filterEnd!: string | null;
  filterStatus!: string[];

  @Watch("colFilter")
  onColFilterChange(
    val: PaymentFilterQueryPayload | null,
    oldVal: PaymentFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<PaymentFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || this.sort.sort || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: PaymentAllPayload) {
    
    await Payment.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = Payment.query()
      .orderBy(this.sort.sort || "created_at", this.sort.order)
      .withAll()
      .get();
    return data;
  }

  get loading(): boolean {
    return !!Payment.store().state.entities.payment.fetching;
  }

  get total() {
    return Payment.store().state.entities.payment.total;
  }

  get numPages(): number {
    const total = Payment.store().state.entities.payment.total;

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  onDateRange(options: { start: string, end: string }) {
    // this.filterStatus = options;
    this.filterStart = options.start || null;
    this.filterEnd = options.end || null;
    this.load();
  }

  compilePayload(merge: Partial<PaymentAllPayload> = {}): PaymentAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): PaymentAllPayload["filter"] {
    const f = filterEmptyQuery(this.colFilter);

    if (this.filterStatus && this.filterStatus.length) {
      f.status = (this.filterStatus || [])
        .join(",");
    }

    const dates = [];

    if (this.filterStart) {
      const s = moment(this.filterStart);
      dates.push(s.startOf('day').format('YYYY-MM-DD'));
      // f.dateRange = this.filterStart;
    }

    if (this.filterEnd) {
      const e = moment(this.filterEnd);
      dates.push(e.startOf('day').format('YYYY-MM-DD'));
      // f.dateRange = this.filterEnd;
    }

    if (dates.length === 2) {
      f['timeRange'] = dates.join(',');
    }

    return { ...f, type: 'stripe', voided: 0, approved: 1 };
  }
}
