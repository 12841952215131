
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { Payment, PaymentFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";
import PaymentStatusBadge from "../details/PaymentStatusBadge.vue";

@Component({
  components: { PaymentStatusBadge },
})
export class StripeList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: Payment[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @Prop({ default: () => false })
  private disableSort!: boolean;

  @Prop({ default: () => false })
  private disableSearch!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: PaymentFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  totalAmount = 0;

  feeAmount = 0;

  recieveAmount = 0;

  fields: CDataTableFields = [
    { key: "status", label: "Status", filter: false, sorter: true },
    { key: "customer_name", label: "Customer", filter: false, sorter: true },
    // { key: "referenceNumber", label: "Ref", filter: false, sorter: true },
    { key: "tech_name", label: "Tech", filter: false, sorter: true },
    
    { key: "mrID", label: "MR", filter: false, sorter: true },
    { key: "amount", label: "Amount", filter: false, sorter: true },
    { key: "stripe_fee", label: "Fee", filter: false, sorter: true },

    { key: "taskNumber", label: "Task", filter: false, sorter: false },
    // { key: "approved", label: "Approved", filter : false, sorter: false  },
    // { key: "voided", label: "Voided", filter : false, sorter: false  },
    { key: "created_at", label: "Made on", filter: false, sorter: true },
  ];

  @Watch("items")
  calculate() {
    const total = this.totalAmount = this.calculateTotal();
    const fee = this.feeAmount = this.calculateStripeFees()
    this.recieveAmount = this.calculateRecieveAmount(total, fee);
  }

  calculateTotal() {
    return (this.items || []).reduce((acc, { amount }) => (acc += Number(amount), acc), 0)
  }

  calculateStripeFees() {
    // Stripe charges 2.9% + 30¢ for each successful card charge.
    return (this.items || []).reduce((acc, { amount }) => (acc += this.calculateStripeFee(Number(amount)), acc), 0)
  }

  calculateStripeFee(amount: number) {
    if (isNaN(amount)) {
      amount = 0;
    }
    return (Number(amount) * 0.029) + 0.30;
  }

  calculateRecieveAmount(total: number, fee: number) {
    return (total - fee);
  }

  mounted() {
    if (this.disableSort || this.disableSearch) {
      this.fields = this.fields.map((f) => {
        f.filter = this.disableSearch ? false : f.filter;
        f.sorter = this.disableSort ? false : f.sorter;
        return f;
      });
    }
  }

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: PaymentFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: Payment) {
    this.$router.push(`/payment/${item.id}`);
  }
}

export default StripeList;
